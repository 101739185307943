/*==============================Fonts include here====================================*/

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Varela+Round&display=swap');

/*====================================================================================*/

* {
    margin: 0;
    padding: 0;
}

html,
body {
    margin: 0;
    padding: 0;
    background-color: #24c3b5;
    overflow-x: hidden;
    box-sizing: border-box;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: absolute;
    min-height: 88vh;
    height: fit-content;
    width: 550px;
    background-color: rgba(241, 241, 250, 0.2);
    left: 50%;
    transform: translateX(-50%);
    top: 0px;
    bottom: 0px;
    padding: 0px;
    border-radius: 18px 18px 18px 18px;
}

.bottom-nav-container-style {
    border-radius: 18px 18px 0 0 !important;
}

.container-back {
    background-color: #4dccc3 !important;
}

@media only screen and (max-width: 576px) {
    .container-noback {
        width: 100%;
        padding: 0px;
        top: 0px;
    }
    .container-back {
        width: 100%;
        padding: 0px;
        top: 0px;
        background: url(../Assets/background.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 0px -30px;
    }
}

.wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    height: auto;
    width: 100%;
    background: #f1f1fa;
    border-radius: 18px 18px 18px 18px;
    padding: 25px;
    bottom: 0px !important;
    box-sizing: border-box;
}

.wrap-full {
    display: flex;
    align-items: center;
    /*Edited*/
    justify-content: flex-start;
    flex-direction: column;
    position: absolute;
    height: fit-content;
    //min-height: 100%;
    min-height: 77vh;
    width: 100%;
    background: #f1f1fa;
    border-radius: 18px 18px 18px 18px;
    padding: 25px 20px;
    bottom: 0px !important;
    top: 100px;
    box-sizing: border-box;
    padding-bottom: 135px;

    &.with-min-height {
        min-height: 98%;
    }

    &.no-top-padding {
        padding-top: 0;
    }
}

.topNav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    top: 25px;
    width: 100%;
    padding: auto 30px !important;
    //margin: 10px 0px 50px 0px;
}

.btn-without-background2,
.btn-without-background2:focus,
.btn-without-background2:hover,
.btn-without-background2:active {
    background-color: Transparent !important;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    width: auto;
    height: auto;
    padding-left: 15px;
}

.left {
    display: flex;
    flex-direction: row;
    float: left;
    align-items: center;
    margin-left: 5px;
}

.topNav p {
    font-family: DM Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #fcfcff;
    margin: 0px 12px;
}

.right {
    display: flex;
    flex-direction: row;
    float: right;
    margin-right: 10px;
}

.backBtn {
    width: 27.33px;
    height: auto;
    padding: 0px 0px 0px 15px;
}

.correctMark {
    width: 23px;
    height: auto;
    padding: 0px 15px 0px 0px;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0px;
}

.logo img {
    width: 142px;
    height: auto;
    margin-top: 50px;
}

.btn-green {
    width: 100%;
    height: 45px;
    top: 598px;
    background: #24c3b5;
    border-radius: 7px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 120%;
    text-align: center;
    color: #ffffff;
    outline: none;
    border: none;
    cursor: pointer;
    margin-top: 15px;
}

.btn-green-with-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45px;
    top: 598px;
    background: #24c3b5;
    border-radius: 7px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 120%;
    text-align: center;
    color: #ffffff;
    outline: none;
    border: none;
    cursor: pointer;
    margin-top: 15px;
}

.btn-green-with-iconImg {
    padding-right: 10px;
}

.btn-disabled {
    opacity: 0.6;
}

.popupOverLay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 15px;

    &.position-absolute {
        position: absolute;
    }
}

.popup-wrap {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    max-width: 427px;
    width: 100%;
    gap: 10px;
    // height: 228px;
    background: #fcfcff;
    border-radius: 10px;
    z-index: 1000;

    &.position-absolute {
        position: absolute;
    }
}

.popup-heading {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #161719;
    // padding-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
}

.popup-body {
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #757575;
    // margin-top: 10px;
    // padding: 30px 0px;
    gap: 10px;
}

.popup-btns {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.popup-btn {
    width: 165px;
    height: 32px;
    left: 66px;
    top: 156px;
    background: #24c3b5;
    border-radius: 5px;
    outline: none;
    border: none;
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    // margin-top: 10px;
    cursor: pointer;

    &.border {
        border: 1px solid #24c3b5;
        background: transparent;
        color: #24c3b5;
    }
}

.reward-input {
    width: 100%;
    background-color: #fff !important;
    border-radius: 10px !important;

    .MuiOutlinedInput-root {
        border-radius: 10px !important;
    }

    .search-icon {
        margin-right: 15px;
    }

    .MuiOutlinedInput-notchedOutline {
        border-color: #f1f1f1 !important;
    }

    .Mui-focused {
        .MuiOutlinedInput-notchedOutline {
            border-color: #24c3b5 !important;
        }
    }
}

/*0.0 Splash styles goes here=============================================================*/

.background {
    background: url(../Assets/background.svg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(241, 241, 250, 0.2);
}

.logo-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 45%;
    transform: translateY(-45%);
}

.logo-area img {
    width: 214px;
    height: auto;
    padding: 20px 0px;
}

.logo-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
}

.copyright-text {
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
    color: #ffffff;
    position: absolute;
    bottom: 30px;
}

/*1.0 Onbording styles goes here=============================================================*/

.input-url {
    background: #ffffff;
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    outline: none !important;
    border: none !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #858585;
    padding: 24px 15px 24px;
    /* margin: 13px 0px 13px 0px; */
    margin: 13px 0px -20px 0px;
}

.error-validation-txt {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 13px;
    text-align: right;
    color: #f77171;
    padding: 10px;
}

.login-btn {
    border: 2px solid #24c3b5 !important;
    box-sizing: border-box;
    border-radius: 7px;
    width: 100%;
    height: 45px;
    outline: none !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 120%;
    text-align: center;
    color: #24c3b5;
    cursor: pointer;
    margin-top: 20px;
}

.login-btn-onboarding {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 2px solid #24c3b5 !important;
    box-sizing: border-box;
    border-radius: 7px;
    width: 100%;
    height: 45px;
    outline: none !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 120%;
    text-align: center;
    color: #24c3b5;
    cursor: pointer;
    margin-top: 20px;
    padding: 0px 10px;
    background: #ddecf3;
    position: relative;

    .text {
        width: 100%;
    }

    img {
        position: absolute;
        right: 10px;
    }
}

.subtitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 120%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #3c3c3c;
    margin-top: 10px;
}

.subtitle-mg {
    margin-bottom: 20px;
    margin-top: 40px;
}

.links,
.links:hover,
.links:active,
.links:focus {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    text-decoration: none !important;
    color: #24c3b5;
    margin: 30px 0px 58px 0px;
    cursor: pointer;
}

.onboard-title {
    color: #3c3c3c;
    font-size: 1.25em;
    font-weight: 600;
}

.onboard-subtitle {
    line-height: 0.1em;
    border-bottom: 1px solid #e3e2e2;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 0.875em;

    span {
        background: #f1f1f1;
        padding: 0 10px;
    }
}

.social-content-button {
    width: 100%;
    top: 454px;
    left: 20px;
    border-radius: 8px;
    border: 1px solid #e3e2e2;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    color: #3c3c3c;
    outline: none;
    background-color: #fff;
    padding: 12px 15px;
    font-weight: 500;
    cursor: pointer;
    min-height: 45px;

    .icon-image {
        width: 16px;
        height: 16px;

        &.s-md {
            width: 20px;
            height: 20px;
        }
    }
}

/*1.1 Sign in styles goes here================================================================*/

.linkedin-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45px;
    left: 78px;
    top: 452px;
    background: #24c3b5;
    border-radius: 8px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 120%;
    text-align: center;
    color: #ffffff;
    outline: none !important;
    border: none;
    cursor: pointer;
    margin: 20px 0px 30px 0px;
}

.linkedin-btn img {
    padding: 0px 15px;
}

/*1.2 Sign up styles goes here================================================================*/

.normal-input {
    background: #ffffff;
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 46px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #858585;
    padding: 11px 15px 11px 15px;
    outline: none !important;

    &.margin-bottom {
        margin-bottom: 15px;
    }
}

.subtitle-mg-signup {
    margin: 30px 0px 10px 0px;

    span {
        color: #3c3c3c;
        padding-left: 5px;
    }
}

.signInLink {
    cursor: pointer;
}

.signInLink p,
.signInLink:hover,
.signInLink:active,
.signInLink:focus {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    //line-height: 120%;
    align-items: center;
    text-align: center;
    // text-decoration-line: underline;
    color: #24c3b5 !important;
    cursor: pointer;
}

.exmfbtm {
    margin-bottom: 15px;
}

.decrease-mg-top {
    margin-top: -30px;
}

/*2.0 Home styles goes here===================================================================*/

.btn-without-background,
.btn-without-background:focus,
.btn-without-background:hover,
.btn-without-background:active {
    background-color: Transparent !important;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    width: auto;
    height: auto;
    padding-left: 0px;
}

.boxbtn-section {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    //margin-bottom: 35px;
    //gap: 10px;
    //grid-gap: 10px;
}

@media screen and (max-width: 960px) {
    .boxbtn-section {
        flex-direction: column;
        gap: 25px;

        button {
            width: 100%;
        }
    }
}

.iconPadding {
    padding: 11px 0px;
}

.nav_right {
    display: flex;
    align-items: center;
    list-style-type: none;
    position: absolute;
    right: 0;
    top: 0;
    margin: 20px 30px;
    z-index: 200;
}

.nav_right > ul {
    display: flex;
    align-items: center;
    list-style-type: none;
}

.nav_right ul li.nr_li {
    margin-left: 25px;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
    position: relative;
}

.nav_right ul li img {
    width: 35px;
    vertical-align: middle;
}

.profile-dropdown-menu {
    position: absolute;
    right: -25px;
    top: 65px;
    display: flex;
    background: #fff;
    border-radius: 7px;
    display: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.profile-dropdown-menu-active {
    display: block;
}

.profile-dropdown-menu-hide {
    display: none;
}

.profile-dropdown-menu .dd_right {
    padding: 15px 0px 0px 0px;
    width: 112px;
    height: auto;
}

.profile-dropdown-fonts {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.165px;
    color: #24c3b5;
    transition: all 0.2s ease;
}

.profile-dropdown-fonts:hover {
    color: #505050;
    font-weight: 600;
}

.profile-dropdown-menu li {
    margin-bottom: 10px;
    color: #24c3b5;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    text-align: center;
    letter-spacing: -0.165px;
    list-style-type: none;
    padding-bottom: 10px;
    border-bottom: 0.2px solid #24c3b5;
}

.profile-dropdown-menu li:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.profile-dropdown-menu a,
.profile-dropdown-menu a:hover {
    text-decoration: none;
    color: #24c3b5;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
}

.profile-dropdown-menu:before {
    content: '';
    position: absolute;
    top: -19px;
    right: 40px;
    border: 10px solid;
    border-color: transparent transparent #fff transparent;
}

.profile-dropdown-main.active .profile-dropdown-menu {
    display: flex;
}

.notify {
    width: 30px !important;
    height: auto;
}

.avatar {
    width: 51px !important;
    height: 51px;
    border-radius: 50%;
}

.boxbtn-mg-home {
    margin: 40px 0px;
}

.extra-mt-homebtn {
    margin-top: 50px;
}

.extra-mt-onboard {
    margin-top: 20px;
}

.notifi-full-click {
    border: none;
    outline: none !important;
    cursor: pointer;
    background-color: #f1f1fa;
}

/*2.1 My profile styles goes here=============================================================*/

.profile-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    /* left: 50%;
    transform: translateX(-50%); */
}

.propic {
    display: inline-block;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    width: 155px;
    height: 155px;
    border-radius: 50%;
    position: relative;
    transition: all 0.3s ease;
    cursor: pointer;
    overflow: hidden;
    box-sizing: border-box;
}

.propic:hover {
    background-color: rgba(0, 0, 0, 0.918);
    opacity: 0.7;
}

.propic p {
    position: absolute;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 120%;
    color: #ffffff;
    padding: 7px 0px;
    width: 100%;
    margin-top: 10px;
    z-index: 100000;
}

.changeIcons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%);
}

.defaultDisplayTxt {
    position: absolute;
    top: 65.5px;
    left: -20.5px;
}

.fill {
    background-color: rgba(34, 34, 34, 0.6);
    height: 18%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
}

.no-fill {
    background-color: rgba(255, 255, 255, 0);
    height: 18%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
}

.propic a {
    text-decoration: none;
    color: #ffffff;
}

.propic-change-btn {
    color: #a9a9a9;
    font-family: Poppins;
}

.userProfileName {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: auto;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.165px;
    color: #24c3b5;
    margin: 12px 0px 53px 0px;
}

.boxbtn-custom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 10px 15px;
    width: 48%;
    height: 111px;
    top: 500px;
    background: #24c3b5;
    border-radius: 10px;
    outline: none !important;
    border: none;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
    margin: 10px 5px;
}

.disabled-background {
    background: #ababab;
}

/*2.1.1 View my id styles goes here===========================================================*/

.input_container {
    position: relative;
    direction: ltr;
    width: 100%;
}

.copy-icon-inside {
    position: absolute;
    bottom: 49%;
    right: 19px;
    width: 13.5px;
    height: 16px;
    margin-bottom: 12px;
}

.id-input {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: static;
    height: 42px;
    width: 100%;
    background: #ffffff;
    border: 2px solid #f1f1f1;
    box-sizing: border-box;
    border-radius: 8px;
    outline: none !important;
    border: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.165px;
    color: #d1d1d1;
    padding: 10px 50px 10px 14px;
    margin-bottom: 22px;
}

.id-input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #d1d1d1;
    opacity: 1;
    /* Firefox */
}

.id-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #d1d1d1;
}

.id-input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #d1d1d1;
}

.blue-paratxt {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.32px;
    color: #24c3b5;
}

.viemyid-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding: 20px 0px;
}

.subtitle2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.165px;
    color: #3c3c3c;
    margin-bottom: 22px;
}

.subtitleAddGc {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.165px;
    color: #3c3c3c;
    margin-bottom: 22px;
    text-align: center;
}

.bluetxt {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.32px;
    color: #24c3b5;
    margin-bottom: 10px;
    word-break: break-all;
}

.viewmyid-desc {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.32px;
    color: #535353;
}

.copy-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    background: #ffffff;
    border-radius: 4px;
    margin: 0px 12px;
}

/*2.2 My contacts styles goes here============================================================*/

.searchInput-container {
    position: relative;
    direction: ltr;
    width: 100%;
}

.search-contact {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: static;
    background: #ffffff;
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 41px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.165px;
    color: #848181;
    outline: none !important;
    border: none;
    padding: 10px 50px 14px 14px;
    margin-bottom: 16px;
}

.search-icon-inside {
    position: absolute;
    bottom: 63%;
    right: 19px;
    width: 17px;
    height: auto;
    margin-top: 0px;
}

.contact-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.left-side {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-self: center;
}

.userpropic-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 16px 20px 16px 15px;
}

.textArea {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.userprofile-name {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #3c3c3c;
    padding-bottom: 4px;
    text-align: left;
}

.receivedRequestTxt {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #24c3b5;
    text-align: left;
}

.right-side {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-self: center;
}

.chevronDown-icon {
    width: 24px;
    height: 24px;
    margin-right: 15px;
}

.pd-right {
    padding-right: 20px;
}

/*2.2.1 Contacts profile styles goes here=====================================================*/

.custom-wrap {
    position: relative;
    width: 100%;
    top: 30px;
    padding: 0px 25px 25px 25px !important;
    box-sizing: border-box;
}

.delete-icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.user-profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 42px;
    position: relative;
    /* left: 50%;
    transform: translateX(-50%); */
}

.user-profile-img {
    width: 155px;
    height: 155px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 33px 5px 33px 5px;
    border-radius: 50%;
}

.user-profile-txt-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.user-profile-txt {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.165px;
    color: #24c3b5;
    padding-left: 5px;
}

.disabled {
    color: #b2b2b2;
}

.clickable {
    cursor: pointer;
}

/*2.2.2 Add new contact from gc styles goes here==============================================*/

.wrap-custom-globalcontacts {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    min-height: 610px;
    width: 100%;
    background: #f1f1fa;
    border-radius: 18px 18px 0px 0px;
    padding: 25px;
    bottom: 0px !important;
    box-sizing: border-box;
}

.btn-green-sm {
    width: 120px;
    height: 36px;
    left: 128px;
    top: 210px;
    background: #24c3b5;
    border-radius: 7px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 120%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    padding: 10px 36px;
    outline: none !important;
    border: none;
    cursor: pointer;
    z-index: 100;
}

.btn-green-sm-mg {
    margin-top: '-20px';
    margin-bottom: '10px';
}

.viemyid-section-mg {
    margin-bottom: -20px;
}

.noResultFountTxt {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 28px;
    letter-spacing: -0.165px;
    color: #848181;
    text-align: center;
}

/*2.2.3 Global contacts profile styles goes here==============================================*/

/*2.3 Issue a Reward styles goes here=========================================================*/

.receivedRequestTxtGrey {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #a29696;
    margin: 3px 0px 3px 0px;
    text-align: left;
}

.wrapReverse {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
}

/*2.4 Issue a Reward styles goes here=========================================================*/

.extraMargin-iR-Btn {
    min-width: 100%;
    /* margin-top: auto;
    bottom: 85px;
    position: static;
    padding-bottom: 0px; */
}

/*2.5 Accept a Reward styles goes here========================================================*/

.reward-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 0px;
}

.reward-item .left-side {
    cursor: pointer;
}

.main-heading {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #3c3c3c;
    padding-bottom: 4px;
}

.role-txt {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #a19696;
    margin-bottom: 4px;
}

.userdetails {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.user-name {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #24c3b5;
}

.user-image {
    width: 11px;
    height: 11px;
    padding-left: 4px;
    border-radius: 50%;
}

.date {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    text-align: center;
    color: #a19696;
    margin-top: 68px;
    position: absolute;
    right: 39px;
}

/*2.4.1 Preparing a reward styles goes here========================================================*/

.issuerDetails-pr {
    width: 100%;
    margin-top: 33px;
}

.sectionTitle-pr {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    color: #3c3c3c;
    width: 100%;
    text-align: left;
    border-bottom: 0.3px solid #a89f9f;
    line-height: 0.1em;
    margin: 10px 0 20px;
}

.sectionTitle-pr span {
    background: #f1f1fa;
    padding-right: 10px;
}

.emptyIssuerImage {
    width: 100px;
    height: 100px;
    border-radius: 10px;
}

.customInputAutoComplete {
    border: 0.5px solid #e5e5e5;
    height: 48px;
    padding: 9px 12px 8px 12px;
    font-size: 13px;
    background: #ffffff;
    box-sizing: border-box;
    font-family: Poppins;
    border-radius: 8px;
    width: 100%;
    outline: none !important;
    position: relative;
    margin-top: 10px;
}

.customInputAutoComplete::placeholder {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 120%;
    /* or 16px */
    display: flex;
    align-items: center;
    color: #bebdbd;
}

/*2.4.1.1 Badge templates styles goes here=========================================================*/

.logoGreen {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 29px;
}

.badges-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 20px 0px;
    margin-top: 99px;
    margin-left: 15px;
}

.badge-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    min-width: 151px;
    height: 157px;
    background: #ffffff;
    border: 1px solid #24c3b5;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 13px;
    outline: none;
    background: transparent;
    cursor: pointer;
}

.badge-img {
    width: 105px;
    height: 105px;
}

.bottomExtraMargin {
    margin-bottom: 50px;
}

/*2.4.1.2 Generated Reward Preview styles goes here================================================*/

.coin-img .boxbtn-section-grp {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    margin-bottom: 35px;
}

/*2.5.1 Received Reward Preview styles goes here===================================================*/

.ReceivedRewardPreview-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #ffffff;
    mix-blend-mode: normal;
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    // border-radius: 15px;
    border-radius: 15px;
    padding: 25px;
    //margin-top: 30px;

    &.no-top-border-radius {
        border-radius: 0 0 15px 15px;
    }

    &.mg-bottom {
        margin-bottom: 10px;
    }
}

.coin-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.title-txt-blue {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.32px;
    color: #24c3b5;
    margin-top: 12px;
}

.normal-txt-black {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.32px;
    color: #3c3c3c;
    padding: 0px 60px;
    margin-top: 12px;
}

.issuerDetails {
    width: 100%;
    margin-top: 0px;
}

.sectionTitle {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    color: #24c3b5;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #24c3b5;
    line-height: 0.1em;
    margin: 10px 0 20px;
}

.sectionTitle span {
    background: #ffffff;
    padding-right: 10px;
}

.issuedTo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
    align-items: center;
}

.imgUserPropic img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    z-index: 10;
    object-fit: contain;
}

.details {
    width: 100%;
    padding-left: 20px;
}

.name {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.32px;
    color: #3c3c3c;
}

.url a,
.url a:hover,
.url a:focus,
.url a:active {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: -0.32px;
    text-decoration-line: underline;
    color: #a19696;
}

.company-url {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 21px;
    letter-spacing: -0.32px;
    color: #3c3c3c;
}

.emptyspace {
    position: relative;
    width: 0px;
    height: 50px;
}

.small-transparent-btn {
    width: 50%;
    min-width: 82px;
    height: 33px;
    border: 2px solid #24c3b5;
    box-sizing: border-box;
    border-radius: 7px;
    outline: none !important;
    cursor: pointer;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    letter-spacing: -0.165px;
    color: #24c3b5;
    background-color: #ffffff;
}

.mg-top-boxbtn {
    // margin-top: 27px;
}

.boxbtn-transparent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 10px 15px;
    width: 48%;
    height: 111px;
    top: 500px;
    background: #ffffff;
    border-radius: 10px;
    outline: none !important;
    border: 2px solid #24c3b5;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #24c3b5;
    cursor: pointer;
    text-decoration: none;
}

/*2.5.2 Request a Reward styles goes here==========================================================*/

.blueTextHeading {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #24c3b5;
    margin: 24px 0px 22px 5px;
}

/*2.6 My Rewards styles goes here==================================================================*/

.merge-btn {
    display: flex;
    position: relative;
    width: 100%;
    /* left: 50%;
    transform: translateX(-50%); */
}

.mergeBtnBlue {
    background: #24c3b5;
    color: #ffffff;
    border: 2px solid #24c3b5;
}

.mergeBtnWhite {
    background: #f8f8ff;
    color: #24c3b5;
    border: 2px solid #24c3b5;
}

.merge-btn-left {
    width: 100%;
    height: 50px;
    border-radius: 10px 0px 0px 10px;
    outline: none !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.165px;
    text-align: center;
    cursor: pointer;
}

.merge-btn-right {
    margin-left: -4px;
    width: 100%;
    height: 50px;
    border-radius: 0px 10px 10px 0px;
    outline: none !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.165px;
    text-align: center;
    cursor: pointer;
}

.reward-item-wrap {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    //justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 20px 0px;
    gap: 10px;
    /* display: none; */
    /*updated newly row reverse*/
}

.reward-item-squre {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 48%;
    min-width: 161px;
    height: 200px;
    border: 1px solid #24c3b5;
    box-sizing: border-box;
    border-radius: 8px;
    //margin: 13px 12px;
    background-color: #ffffff;
    cursor: pointer;
    padding: 0px 5px;

    img {
        object-fit: contain;
    }

    &[data-selectable='true'] {
        height: 210px;
        justify-content: flex-start;

        .extension-btn {
            display: none;
        }
    }

    .checkbox-wrap {
        display: flex;
        width: 100%;
        justify-content: flex-start;
    }

    &.on-extension-reward {
        height: 230px;
    }
}

.coin-img {
    width: 94px;
    height: 94px;

    &.badge-preview {
        width: 95%;
        height: auto;
    }
}

.role-heading {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 120%;
    text-align: center;
    color: #3c3c3c;
    margin-top: 10px;
}

.mg-top-item {
    margin-top: 10px;
}

.user-heading {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 120%;
    text-align: center;
    color: #24c3b5;
    padding-left: 3px;
}

.user-img {
    width: 11px;
    height: 11px;
}

.date-txt {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 120%;
    text-align: center;
    color: #a19696;
    margin-top: 3px;
}

.emptyRewardsTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #24c3b5;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    margin-top: 40px;
    width: 70%;
}

/*2.6.1 My Reward Preview styles goes here=========================================================*/

/*2.6.2 My Issued Rewards styles goes here=========================================================*/

/*2.6.3 Issued Reward Preview styles goes here=====================================================*/

.boxbtn-section-irp {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    margin-bottom: 35px;
}

/*2.7 Trophy Case styles goes here=================================================================*/

.heading-txt-blue {
    text-align: center !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    color: #24c3b5;
    width: 100%;
}

.mg-top-decrease {
    margin-top: -30px;
}

/*2.7.1 Received Reward Preview in Trophy Case styles goes here====================================*/

/*2.7.2 Trophy Case - Public View styles goes here=================================================*/

.header-logo-mini {
    width: 95px;
    height: 51.5px;
    margin-top: 20px;
}

.emptyTrophyCase {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    color: #24c3b5;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    margin-top: 325px;
    width: 70%;
}

/*2.7.3 Received Reward Preview in public Trophy Case styles goes here=============================*/

/*2.8.0 Verified reward styles goes here===========================================================*/

.blacktxt {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.32px;
    color: #3c3c3c;
    margin-bottom: 10px;
}

/*2.8.1 Verified reward styles goes here===========================================================*/

.verify-btnHN2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 85px;
    width: 100%;
    height: 54px;
    background: #24c3b5;
    border-radius: 10px;
    outline: none !important;
    border: none;
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #fcfcff;
    margin: 15px 0px;
    cursor: pointer;
}

.verify-sign {
    padding-right: 6px;
    width: 12.75px;
    height: 9.92px;
}

/*2.9 Invite Collegues styles goes here============================================================*/

.extra-pd {
    padding: 10px 50px 20px 50px;
    text-align: center;
}

.extra-mb {
    margin-bottom: 20px;
}

.extra-mt {
    margin-bottom: 35px;
}

.extra-mt2 {
    margin-bottom: 10px;
}

.pointer {
    cursor: pointer;
}

.hidden {
    display: none;
}

.emptyBackBtnSpace {
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

.tooltipText {
    position: absolute;
    right: 0px;
    top: 34px;
    z-index: 100;
    margin: auto;
    padding: 6px 12px;
    background-color: #3a3a3a;
    border-radius: 4px;
    color: #fff;
    border-color: none;
    margin-top: 6px;
    font-family: 'poppins';
    font-size: 10px;
}

.tooltipTextDid {
    position: absolute;
    right: 58px;
    top: 340px;
    z-index: 100;
    margin: auto;
    padding: 6px 12px;
    background-color: #3a3a3a;
    border-radius: 4px;
    color: #fff;
    border-color: none;
    margin-top: 6px;
    font-family: 'poppins';
    font-size: 10px;
}

.tooltipTextRewardPreview {
    position: absolute;
    right: -18px;
    top: 43px;
    z-index: 100;
    margin: auto;
    padding: 6px 12px;
    background-color: #3a3a3a;
    border-radius: 4px;
    color: #fff;
    border-color: none;
    margin-top: 6px;
    font-family: 'poppins';
    font-size: 10px;
}

.tooltipTextShareComponent {
    position: absolute;
    right: 55px;
    bottom: 15px;
    z-index: 100;
    margin: auto;
    padding: 6px 12px;
    background-color: #3a3a3a;
    border-radius: 4px;
    color: #fff;
    border-color: none;
    margin-top: 6px;
    font-family: 'poppins';
    font-size: 10px;
}

.tooltipTextShareComponent::before {
    content: '';
    position: absolute;
    top: -11px;
    right: 33px;
    border: 6px solid;
    border-color: transparent transparent #3a3a3a transparent;
}

.tooltipText:before,

/* .tooltipTextDid:before, */

.tooltipTextRewardPreview::before {
    content: '';
    position: absolute;
    top: -11px;
    right: 33px;
    border: 6px solid;
    border-color: transparent transparent #3a3a3a transparent;
}

.exmgright {
    margin-right: 10px;
}

/*2.1.2.1 Anchoring the profile styles goes here===========================================*/

.greenTxt {
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #24c3b5;
    margin: 20px 0px 10px 0px;
}

.greenTxtBulletsForm {
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    color: #24c3b5;
    margin: 10px 0px 20px 0px;
}

.txtGuide {
    text-align: left;
    margin: 0px 20px;
}

.greenTxtHeading {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #24c3b5;
    margin: 20px 0px 40px 0px;
}

.greenTxtInput {
    position: absolute;
    width: 100%;
    height: 46px;
    background: #ffffff;
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    border-radius: 8px;
}

.domainConfirmTxt {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: static;
    height: 46px;
    width: 100%;
    background: #ffffff;
    border: 2px solid #f1f1f1;
    box-sizing: border-box;
    border-radius: 8px;
    outline: none !important;
    border: none;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 120%;
    color: #858585;
    padding: 11px 15px 11px 15px;
    margin-bottom: -20px;
}

.decreaseMargin {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0px;
}

.decreaseMargin img {
    width: 142px;
    height: auto;
    margin-top: -50px;
}

.greenCheckTxt {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #24c3b5;
    margin: 20px 0px;
}

.greenCheckTxt img {
    margin: 2px 5px 0px 0px;
    width: 25px;
}

.decrease-mg-top2 {
    margin-top: -40px;
}

.verifyLink {
    margin-bottom: 20px;
}

.copy-icon-inside-domainverify {
    position: absolute;
    bottom: -11%;
    right: 13px;
    width: 16.5px;
    height: 17px;
    margin-bottom: 12px;
    background-color: #fff;
    padding: 10px 18px;
}

.sectionTitleClaimDomain {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #24c3b5;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #d1d1d1;
    line-height: 0.1em;
    margin: 10px 0px 20px;
}

.sectionTitleClaimDomain span {
    background: #f1f1fa;
    padding-right: 10px;
}

.blackHeadingsmall {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #161719;
    margin: 10px 0px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

.helpIcon {
    top: 6px;
    left: 6px;
    position: relative;
    cursor: pointer;
}

.txtValueHelpText {
    position: absolute;
    left: 110px;
    top: 300px;
    z-index: 100;
    margin: auto;
    padding: 6px 12px;
    background-color: #3a3a3a;
    border-radius: 4px;
    color: #fff;
    border-color: none;
    margin-top: 6px;
    font-family: 'poppins';
    font-size: 10px;
    max-width: 200px;
}

.traansparent-btn-domain {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #24c3b5 !important;
    box-sizing: border-box;
    border-radius: 7px;
    width: 100%;
    height: 45px;
    outline: none !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 120%;
    text-align: center;
    color: #24c3b5;
    cursor: pointer;
    margin-top: 20px;
    padding: 0px 10px 0px 210px;
}

.editIcon {
    width: 20px;
    height: 20px;
}

.editIcon-mg-right {
    margin-right: 5px;
}

.txtValueHelpTextDomain {
    position: absolute;
    left: 140px;
    top: 60px;
    z-index: 100;
    margin: auto;
    padding: 6px 12px;
    background-color: #3a3a3a;
    border-radius: 4px;
    color: #fff;
    border-color: none;
    margin-top: 6px;
    font-family: 'poppins';
    font-size: 10px;
    max-width: 200px;
}

.logoGreen-mg {
    margin: 30px 0px 50px 0px;
}

.addSection {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.addSectionBtn {
    outline: none !important;
    border: none;
    cursor: pointer;
}

.popup-wrap-addSections {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 15px;
    width: 427px;
    height: 300px;
    background: #fcfcff;
    border-radius: 10px;
    z-index: 1000;
}

.popup-heading {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #161719;
    padding-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
    // margin-bottom: 20px;
}

.colorGreen {
    color: #24c3b5;
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    padding-bottom: 10px;
    width: 100%;
    margin-bottom: 20px;
}

.buttonRow {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    padding: 10px;
}

.addSectionAttribute {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    padding: 20px 0px;
}

.sectionEdit {
    width: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.sectionAttribute {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #24c3b5;
    width: 100px;
    text-align: left;
    overflow-wrap: anywhere;
}

.addSectionAttributePreview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 20px 20px;
}

.sectionAttributePreview {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #3c3c3c;
    text-align: left;
}

.sectionValue {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #a8a4a4;
    text-align: left;
    overflow-wrap: anywhere;
}

/*2.6.1 Share Component styles goes here==========================================================*/

.share-component-overLay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 0;
}

.share-component-overLay-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 0;
    border-radius: 18px;
}

.share-component {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    position: absolute;
    z-index: 1000000;
    background-color: #f8f8ff;
    bottom: 0;
    padding: 22px 30px 100px 30px;
    box-sizing: border-box;
    border-radius: 18px;
}

.share-component-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.share-component-header h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #000000;
}

.share-component-header button {
    width: 36px;
    height: 36px;
    border-radius: 50px;
    background-color: #f5f5f5;
    outline: none !important;
    border: none !important;
    cursor: pointer;
}

.share-component-sub-heading {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    display: flex;
    align-items: center;
    color: #000000;
    margin-bottom: 10px;
}

.share-component-copy-wrap {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.reduce-bottom-margin {
    margin-bottom: -30px;
}

.copy-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    padding: 15px;
    margin-bottom: 30px;
}

.copy-input-container-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.copy-input-container button {
    background: #24c3b5;
    border-radius: 6px;
    outline: none !important;
    border: none;
    cursor: pointer;
    width: 100%;
    max-width: 90px;
    max-height: 37px;
    padding: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #ffffff;
    margin-left: 5px;
}

.copy-input-container input {
    height: 20px;
    left: 61px;
    top: 750px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #000000;
    outline: none !important;
    border: none !important;
    margin-left: 10px;
}

.popup-bold-input {
    background: #ffffff;
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 46px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #000000;
    padding: 11px 15px 11px 15px;
    outline: none !important;
}

.rewardfile-upload-field [type='file'] {
    color: #878787;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
}

.rewardfile-upload-field [type='file']::-webkit-file-upload-button {
    background: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 4px;
    color: #878787;
    cursor: pointer;
    font-size: 12px;
    outline: none;
    padding: 10px 25px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    border-radius: 8px;
}

/*Certificate styles goes here==========================================================*/

.certificate-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    background-color: #1f193d;
    border-radius: 10px;
    min-height: 675px;
}

.certificate-frame {
    position: absolute;
    z-index: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
}

.certificate-section-one,
.certificate-section-two,
.certificate-section-three {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
    text-align: center;
}

.certificate-container p {
    font-family: 'Varela Round';
    color: #fff;
}

.certificate-container h1,
h3,
h6,
h4 {
    font-family: 'Varela Round';
    color: #fff;
    font-weight: 500;
}

.certificate-section-one {
    margin: 50px 50px;
}

.certificate-logo {
    width: 100px;
    height: auto;
    margin-bottom: 30px;
    z-index: 10;
}

.certificate-logo-footer {
    width: 100px;
    height: auto;
    margin-bottom: 60px;
    z-index: 10;
}

.certificate-header-txt {
    text-align: center;
    font-size: 10px;
    padding: 0px 20px;
    line-height: 18px;
    text-decoration: none;
}

.certificate-section-two,
.certificate-section-three {
    margin-bottom: 50px;
}

.certificate-attributes-section {
    margin-bottom: 10px;
}

.certificate-section-four {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-evenly;
    z-index: 10;
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
}

.certificate-signature {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.certificate-signature img {
    width: 80px;
    height: auto;
}

.upper-border {
    width: 50%;
    height: 1px;
    background-color: #fff;
    border: none;
}

.certifi-description {
    margin-top: 0.5rem;
}

.public-pro-link {
    cursor: pointer;
}

/* Tab Section styles ===========================================================*/

.tabSection {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    // margin-bottom: 20px;
    overflow-x: auto;
}

.tabSectionBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background-color: #e7e7e7;
    min-width: 100px;
    height: 50px;
    border-radius: 10px 10px 0px 0px;
    outline: none !important;
    border: none !important;
    cursor: pointer;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #24c3b5;
    margin-right: 5px;
    position: relative;
    img {
        object-fit: contain;
        width: 15px;
    }
}

.nft-indicator {
    position: absolute;
    top: 0px;
    right: -2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #c3ab24;
}

.tabSectionBtnActive {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    position: relative;
    background-color: #24c3b5;
    min-width: 100px;
    height: 50px;
    border-radius: 10px 10px 0px 0px;
    outline: none !important;
    border: none !important;
    cursor: pointer;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #fff;
    margin-right: 5px;
    img {
        object-fit: contain;
        width: 15px;
        filter: brightness(10);
    }
}

.tabSectionBtn:active,
.tabSectionBtn:focus,
.tabSectionBtn:hover {
    background-color: #24c3b5;
    color: #ffffff;
    img {
        filter: brightness(10);
    }
}

.insideComponentsWrap {
    // padding: 5px 20px 20px 20px;
    bottom: 0px !important;
    top: 100px;
    width: 100%;
    min-height: fit-content;

    .checkbox-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0px 0px 20px 10px;

        .greenTxt {
            margin: 0;
            width: max-content;
        }
        .note {
            font-size: 13px;
            color: #848181;
        }
    }
    .view-nft {
        text-align: center;
        button {
            margin-bottom: 10px;
        }
        button:disabled {
            opacity: 0.5;
            cursor: default;
        }
        .note {
            font-size: 13px;
            color: #848181;
        }
    }
}

.greenCheckBox {
    color: #24c3b5 !important;
}

.topSpace {
    margin-top: 1.5rem;
}

.mediumTopSpace {
    margin-top: 0.3rem;
}

.extension-btn {
    padding: 4px 10px;
    margin-top: 10px;
    color: #fff;
    background-color: #24c3b5;
    outline: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: block;

    &[data-active='true'] {
        // display: block;
    }

    &:hover {
        background-color: #1fb0a4;
    }
}

.double-buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;

    button {
        width: max-content;
        padding: 5px 15px;
        width: 100%;
    }
}

.social-login-icons {
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
    align-items: center;

    .social-login-button {
        cursor: pointer;
    }
}

.form-style {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
}

.nft-status {
    display: flex;
    gap: 10px;
    img {
        filter: grayscale(1);
    }
    p {
        color: #c8c8c8;
    }
    &-active {
        display: flex;
        gap: 10px;
        p {
            color: #24c3b5;
        }
    }
    &-pending {
        display: flex;
        gap: 10px;
        p {
            color: #ff6c17;
        }
    }
}
